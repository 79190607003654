export const blackList = [
    '0x05B39319612dcdB8b68CE38DCc049ab0497FfF3c',
    '0x0e8139Db0b4F60a028e4291e794D03B62D4e7aDb',
    '0x0554035beA109684D598FAFED7eE351BBf9f6678',
    '0xA9c01202d97424b1899540ea6a61FA6eDce9d3D9',
    '0x9Ee9F40a64a4A4FDe11cba503B57680423AB3369',
    '0x32C42A1cAD78A8BC558Fc2171174A03AE0d26ba5',
    '0x2adE4c7B1E794a8E60F3c674b3C9228926620b1D',
    '0x21D1BB14a4a233b1a08e4F64b545129A4dE17818',
    '0xd3f02ee9688c0c07cD31c241540A3A31Ca88aCab',
    '0x576d98Cf9b9F71080eF145cf4D21428aF1C8a2b6',
    '0x5f711A017e0747Df56E79418E1F447CE8F7f0990',
    '0xB3D4DA8b76B1E2AEdf05b892625752112A10cA50',
    '0x966D6522B59a222F8E17dB81a2Ed24086912A949',
    '0x2665A14dB2261Ac0BF2ae43C6D2Ebd5042A93E69',
    '0x92e70c66e629a5cc6d9716960463c77AE780609D',
    '0xc0308D33BDA70f5281a55c746293ccFC89497d96',
    '0xa307339936abB66f1aa675926d38183Dd09E12aB',
    '0xA86dC2fEa72A3C5df6DECE2F50E2032364dB7756',
    '0xd657695b41473615081b0286792BdE8eA47E17a0',
    '0x0734888340bb8c3D97B419E9b1897a05d8583815',
    '0x7c8f59d5B765d2BdFFe6De0CA472DB85B0B5e464',
    '0xf8FB8AeabCB327D1468EB4f995E92Fc264C0f4B3',
]
    ;